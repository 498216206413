<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      fullscreen
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="green darken-2">
          <v-toolbar-title>User Information</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeMainDialog()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row>
          <v-col>
            <v-card class="elevation-0">
          <v-card-text>
              <v-row justify="center">
                <v-col
                  cols="12"
                  xl="4"
                  lg="4"
                  md="4"
                  class="d-flex justify-center"
                >
                  <v-card class="mt-4 text-center elevation-0">
                    <v-card-text>
                    <v-hover>
                      <template v-slot:default="{ hover }">
                      <v-card class="rounded-circle elevation-6" width="128" height="128" style="left: 50%; transform: translateX(-50%)">
                          <v-img
                            class="rounded-circle"
                            :src="formData.imageUrl"
                            width="128" height="128"
                          >
                          </v-img>
                          <v-fade-transition>
                            <v-overlay
                              v-if="hover"
                              absolute
                              color="header"
                            >
                              <v-file-input
                              accept="image/*"
                              v-model="image"
                              @change="onFileChange"
                              id="fileInput"
                              style="display: none;"
                            >
                            </v-file-input>
                            <v-btn icon x-large dark onclick="document.getElementById('fileInput').click()"><v-icon>mdi-account-edit-outline</v-icon></v-btn>
                            </v-overlay>
                          </v-fade-transition>
                            </v-card>
                          </template>
                          </v-hover>
                    </v-card-text>
                    <v-card-text class="text-center">
                      <h4 class="text-h5 mb-3 text--primary text-uppercase">
                        {{ formData.FirstName }} {{ formData.LastName }}
                      </h4>

                      <div class="text--secondary">
                      {{formData.email}}
                      </div>
                      <v-list dense class="text-left mt-2">
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon>mdi-account-lock-open</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content left>
                              <v-list-item-title>{{formData.RoleDescription}}</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsRA == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Recommending Approval</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="formData.roleID != 2">
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsFM == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Fund Manager</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item>
                            <v-list-item-icon>
                              <v-icon v-if="formData.BACaccess == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>BAC Access</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="formData.roleID != 3">
                            <v-list-item-icon>
                              <v-icon v-if="formData.IsPA == 1">mdi-account-lock-open</v-icon>
                              <v-icon v-else>mdi-account-lock</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title>Person Accountable</v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                      </v-list>
                      <!-- <v-btn
                        class="mr-0"
                        color="primary"
                        min-width="100"
                        rounded
                      >
                        Follow
                      </v-btn> -->
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col
                  cols="12"
                  lg="8"
                  md="8"
                >
                  <v-card flat>
                    <v-card-text>
                    <v-form ref="FormRef1">
                        <v-row>
                          <v-col cols="12">
                            <span class="text-subtitle-1">Personal Information</span>
                            <v-divider></v-divider>
                          </v-col>
                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="First Name"
                              :rules="[formRules.required]"
                              v-model="formData.FirstName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="Middle Name"
                              :rules="[formRules.required]"
                              v-model="formData.MiddleName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="4"
                          >
                            <v-text-field
                              color="green"
                              label="Last Name"
                              :rules="[formRules.required]"
                              v-model="formData.LastName"
                            />
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-autocomplete
                              v-model="formData.PositionID"
                              item-text="PositionDescription"
                              item-value="PositionID"
                              :rules="[formRules.required]"
                              :items="positions"
                              color="header"
                              label="Position">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-autocomplete
                              v-model="formData.DesignationID"
                              item-text="DesignationDescription"
                              item-value="DesignationID"
                              :rules="[formRules.required]"
                              :items="designations"
                              color="header"
                              label="Designation">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-autocomplete
                              v-model="formData.OfficeID"
                              item-text="OfficeDescription"
                              item-value="OfficeID"
                              :rules="[formRules.required]"
                              :items="offices"
                              color="header"
                              label="Office">
                            </v-autocomplete>
                          </v-col>

                          <v-col
                            cols="12"
                            md="6"
                          >
                            <v-autocomplete
                              v-model="formData.DeliveryUnitID"
                              item-text="DeliveryUnitDescription"
                              item-value="DeliveryUnitID"
                              :rules="[formRules.required]"
                              :items="deliveryunits"
                              color="header"
                              label="Delivery Unit">
                            </v-autocomplete>
                          </v-col>

                          <v-col cols="12">
                            <v-textarea
                              v-model="formData.About"
                              color="green"
                              label="About Me"
                            />
                          </v-col>

                          <!-- <v-col
                            cols="12"
                            class="text-right"
                          >
                          <v-btn
                              color="red darken-3"
                              dark
                              outlined
                              class="mr-2"
                              @click="changePass"
                            >
                              Change Password
                            </v-btn>

                            <v-btn
                              color="header"
                              dark
                              @click="update"
                              :loading="isLoading"
                            >
                              Update Profile
                            </v-btn>
                          </v-col> -->
                        </v-row>
                    </v-form>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
          </v-card-text>
          </v-card>
          </v-col>
        </v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    data: {},
  },
  data: () => ({
    dialog: false,
    formData: {
        FirstName: null,
        MiddleName: null,
        LastName: null,
        EducQuali: null,
        PositionID: null,
        DesignationID: null,
        OfficeID: null,
        DeliveryUnitID: null,
        About: null,
        email: null,
        password: null,
        current_password: null,
        new_password: null,
        confirm_password: null,
        roleID:null,
        IsRA: null,
        BACaccess: null,
        RoleDescription: null,
        imageUrl: "https://www.pngkit.com/png/full/302-3022217_roger-berry-avatar-placeholder.png",
      },
      offices: [],
      positions: [],
      designations: [],
      deliveryunits: [],
      roles: [],
      role: null,
      // dialogChangePass: false,
      // show1: false,
      // show2: false,
      // show3: false,
      image: undefined,
  }),
  watch: {
    data: {
      handler(data) {
        if (data.UserID) {
          this.getProfilePic(data.ProfilePic);
          this.formData.FirstName = data.FirstName
          this.formData.MiddleName = data.MiddleName
          this.formData.LastName = data.LastName
          this.formData.EducQuali = data.EducQuali
          this.formData.PositionID = data.PositionID
          this.formData.DesignationID = data.DesignationID
          this.formData.OfficeID = data.OfficeID
          this.formData.DeliveryUnitID = data.DeliveryUnitID
          this.formData.About = data.About
          this.formData.email = data.email
          this.formData.password = data.password
          this.formData.roleID = data.roleID
          this.formData.IsRA = data.IsRA
          this.formData.BACaccess = data.BACaccess
          this.formData.IsPA = data.IsPA
          this.formData.RoleDescription = data.RoleDescription
          console.log(this.formData)
          this.dialog = true;
        }
      },
      deep: true,
    },
  },
  mounted(){
    this.getUtilities();
  },
  methods: {
    closeMainDialog() {
      this.dialog = false;
      this.eventHub.$emit("closeViewUserModal", false);
    },
    getUtilities(){
      this.axiosCall("/getPositionDesignationOfficeOutside", "GET").then((res) => {
        this.positions = res.data.data.position;
        this.offices = res.data.data.office;
        this.designations = res.data.data.designation;
        this.deliveryunits = res.data.data.deliveryunit;
        this.roles = res.data.data.role;
        this.roles.forEach(item => {
          if(item.RoleID == this.formData.roleID){
            this.role = item.RoleDescription;
          }
        });
      });
    },
    getProfilePic(filename){
        let data = new FormData();
        data.append('ProfilePic', filename || '');
              this.axiosCall(
            '/getProfilePic',
            'POST',
            data
        )
        .then((res) => {
            if(res.data.status) {
              this.formData.imageUrl = res.data.data
            }else {
              this.formData.imageUrl = "https://www.pngkit.com/png/full/302-3022217_roger-berry-avatar-placeholder.png"
            }
        })
    },
  }
};
</script>